import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import { ReactComponent as WebsiteIcon} from "images/website-icon.svg";

export const recipes = [
        {
            id: "1",
            title: "Baingan Bharta",
            description: "Vegan dish made up of smoked and mashed eggplant",
            long_description: "Baingan Bharta is a perfect local vegan dish. It originated in India but is also famous in Pakistan and Bangladesh. Baingan Bharta could be made at any time of the day but is generally preferred for Lunch and Dinner as a main course. It serves best with Roti (Flatbread). The history of this very old recipe is less known. The key ingredients used to make Baingan Bharta are: Eggplant, Garlic, Tomato, and Onion. The dish was named Baingan Bharta because Baingan means Eggplant and Bharta means mashed. Overall, the dish is made up of smoked and mashed eggplant.",
            imageSrc: "./images/recipes/baingan_bharta/final_dish.jpg",
            cook: "Prajwal Dhatwalia",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 6,
            taste: "Salty",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "1",
                    instruction: "Start by rising the eggplants and pat dry.",
                    img: ""
                },
                {
                    id: "2",
                    instruction: "Then make 3 slits on each eggplant with a knife. In those slits, insert a large clove of garlic in each.",
                    img: "./images/recipes/baingan_bharta/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "Place the eggplants on an oven tray and set the oven temperature at 400 degrees Fahrenheit.",
                    img: ""
                },
                {
                    id: "4",
                    instruction: "Turn it often for around 30 mins or until completely cooked. To check if it’s roasted, insert a knife inside the eggplant, it should go easily. Once roasted, wrap it in aluminum foil to cool.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "Meanwhile, chop 12 cloves of garlic, half a bunch of cilantro, 3 onions, and 6 tomatoes.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Now, peel the roasted eggplant and mash it with the help of a spatula or potato masher.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "After that, heat the pan on a medium flame and spread 5 tablespoons of oil. Once the oil is hot, add chopped garlic and 3 tablespoons of chili powder. Sauté for 2 minutes or until they start changing color.",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "Then add the chopped onion and cook for 2-3 minutes until softened.",
                    img: "./images/recipes/baingan_bharta/step_8.jpg"
                },
                {
                    id: "9",
                    instruction: "Now, add the chopped tomatoes and stir fry. Cook the tomatoes for around 5 minutes until very soft.",
                    img: ""
                },
                {
                    id: "10",
                    instruction: "Add the mashed/roasted eggplant into the pan and mix well.",
                    img: "./images/recipes/baingan_bharta/step_10.jpg"
                },
                {
                    id: "11",
                    instruction: "Add 3 tablespoons of salt and give a good stir. Cook the bharta for 10 mins while stirring often",
                    img: "./images/recipes/baingan_bharta/step_11.jpg"
                },
                {
                    id: "12",
                    instruction: "Add the chopped cilantro and cook for another 10 minutes.",
                    img: ""
                }
            ],
            ingredients: [
                {
                    name: "3 Eggplants",
                    special_tip: null
                },
                {
                    name: "9-12 cloves of Garlic ",
                    special_tip: "(roasting within eggplants + externally added)"
                },
                {
                    name: "6 Tomatoes",
                    special_tip: null
                },
                {
                    name: "3 Onions",
                    special_tip: null
                },
                {
                    name: "½ bunch of Cilantro leaves",
                    special_tip: null
                },
                {
                    name: "3 teaspoons Chili powder",
                    special_tip: null
                },
                {
                    name: "3 teaspoons Salt",
                    special_tip: null
                },
                {
                    name: "5 tablespoons Vegetable oil",
                    special_tip: null
                },                
            ],
            recipe_category: [
                {
                    name: "Lunch",
                    img: ""
                },
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Vegtarian",
                    img: ""
                },
                {
                    name: "High Fiber",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=rJWQsAKwMpU",
            author: "Mann Koner"
        },
        {
            id: "2",
            title: "Bottle Gourd Curry",
            description: "Curry with fried bottle gourd balls",
            long_description: "A dish that contains both tanginess and spiciness, can keep your body hydrated, and can be a perfect summer cuisine, all in one. Bottle gourd curry is an Indian dish, and in India, it's known as “Doodhi Kofta” where Doodhi means bottle gourd, and kofta means fried balls of the bottle gourd mixture. This dish could be made any time of the year, but people mostly prefer it in summers. Bottle gourd is an excellent source of energy and it helps in keeping the body hydrated. This dish has two components, first is a curry made up of some basic aromatic spices, onions, and tomato, and second component is the fried bottle gourd balls mixed with some chickpea flour and spices. This dish is an entrée and tastes best with Indian bread or rice.",
            imageSrc: "./images/recipes/bottle_gourd_curry/final_dish.jpg",
            cook: "Nandini Parekh",
            cooking_time: "1 hr",
            difficulty_level: "Medium",
            number_of_portions: 2,
            taste: "Tangy and Spicy",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "title",
                    instruction: "To make Gravy",
                    img: ""
                },
                {
                    id: "1",
                    instruction: "Take a pan, put the butter block or ghee in the pan, and let it melt on a medium flame.",
                    img: ""
                },
                {
                    id: "2",
                    instruction: "Add cinnamon, cloves, cardamom, hing, and ginger chili paste. Stir the ingredients slowly.",
                    img: "./images/recipes/bottle_gourd_curry/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "After 2 mins, add the chopped onions, and keep stirring.",
                    img: "./images/recipes/bottle_gourd_curry/step_3.jpg"
                },
                {
                    id: "4",
                    instruction: "Take a small bowl, pour a little oil in the bowl, add salt, turmeric powder, coriander powder, and a little water. Mix the ingredients properly.",
                    img: "./images/recipes/bottle_gourd_curry/step_4.jpg"
                },
                {
                    id: "5",
                    instruction: "Now, add the mixture into the pan and keep stirring for a while.",
                    img: "./images/recipes/bottle_gourd_curry/step_5.jpg"
                },
                {
                    id: "6",
                    instruction: "After some time, when you smell spices cooking, add tomato puree into it. Let it cook for a while.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "Turn off the flame now.",
                    img: ""
                },
                {
                    id: "title",
                    instruction: "To make Kofta balls",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "First, wash the bottle gourd, peel it, and grate it well.",
                    img: "./images/recipes/bottle_gourd_curry/step_8.jpg"
                },
                {
                    id: "9",
                    instruction: "Then take the grated bottle gourd and squeeze the water out and place it in a bowl (if you want, you can use this water in the gravy)",
                    img: "./images/recipes/bottle_gourd_curry/step_9.jpg"
                },
                {
                    id: "10",
                    instruction: "Pour some oil in a different pan and let it heat on a medium flame. Once heated, slowly put the balls into the pan to fry. Make sure the kofta doesn’t remain raw inside.",
                    img: "./images/recipes/bottle_gourd_curry/step_10.jpg"
                },
                {
                    id: "11",
                    instruction: "Next, add the fried kofta balls immediately to the gravy, and mix it well.",
                    img: ""
                },
                {
                    id: "12",
                    instruction: "Serve it with indian bread (paratha) or rice.",
                    img: ""
                }
            ],
            ingredients: [
                {
                    name: "To make gravy",
                    special_tip: "title"
                },
                {
                    name: "1 block Butter",
                    special_tip: null
                },
                {
                    name: "2-3 Cinnamon Sticks",
                    special_tip: null
                },
                {
                    name: "4-5 Cloves",
                    special_tip: null
                },
                {
                    name: "7-8 Cardamom",
                    special_tip: null
                },
                {
                    name: "pinch of Asafoetida",
                    special_tip: null
                },
                {
                    name: "2 Onions",
                    special_tip: "(finely chopped)"
                },
                {
                    name: "3 Tomatoes",
                    special_tip: null
                },
                {
                    name: "2 tablespoons Chili",
                    special_tip: null
                },
                {
                    name: "1 teaspoon Turmeric Powder",
                    special_tip: null
                }, 
                {
                    name: "3 teaspoons Coriander Powder",
                    special_tip: null
                },
                {
                    name: "To make Kofta balls",
                    special_tip: "title"
                },
                {
                    name: "1 Bottle Gourd",
                    special_tip: null
                },
                {
                    name: "Chickpea flour (to blend the kofta)",
                    special_tip: null
                },
                {
                    name: "Ginger Chilies paste",
                    special_tip: null
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "2 tablespoons Chili",
                    special_tip: null
                },
                {
                    name: "1 teaspoon Turmeric Powder",
                    special_tip: null
                }, 
                {
                    name: "3 teaspoons Coriander Powder",
                    special_tip: null
                },
                {
                    name: "Cooking Oil",
                    special_tip: null
                },
            ],
            recipe_category: [
                {
                    name: "Lunch",
                    img: ""
                },
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Vegtarian",
                    img: ""
                },
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=LcqNiWJ-26M",
            author: "Mann Koner"
        },
        {
            id: "3",
            title: "Braised Chicken Wings",
            description: "sweet & salty Chicken Wings with a traditional Chinese cooking style",
            long_description: "A great combination of sweet, salty, and umami flavor that comes with a traditional Chinese cooking style. It is a common stir-fry and stew dish mostly found in the north china. The Chinese technique used in this dish is called “Hong Shao” which means red braise and is named so because of the dark red and brownish color of the dish. Red Braises represents the classic flavor of many Chinese dishes. This technique is commonly used in many Chinese dishes throughout the country. The restaurants and households in China have various versions of red braised recipes for different dishes, including Chicken wings, Pork belly, beef, and so on. “Hong Shao” is used differently in different regions, for example, in Sichuan it is usually used with spicy bean paste or “Doubanjiang”. However, in northern China, people usually use caramel and soy sauce for seasoning. The dish serves best with hot steamed rice.",
            imageSrc: "./images/recipes/braised_chicken_wings/final_dish.jpg",
            cook: "Chen Yang",
            cooking_time: "30 mins",
            difficulty_level: "Easy",
            number_of_portions: 2,
            taste: "Mild Spicy",
            country: "China",
            countryIcon: "./images/country/china1.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "First, cut chicken wings (cut the meat open but do not chop them into small pieces) and chop thin slices of ginger.",
                    img: "./images/recipes/braised_chicken_wings/step_1.jpg"
                },
                {
                    id: "2",
                    instruction: "Take a bowl, put chicken wings in cold water and add 10ml of cooking wine, one whole green onion, ginger slices. Cook until you see bubbles in the water.",
                    img: "./images/recipes/braised_chicken_wings/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "Now, pour out the boiled water and wash the chicken wings with warm water.",
                    img: "./images/recipes/braised_chicken_wings/step_3.jpg"
                },
                {
                    id: "4",
                    instruction: "Take a pan, spread some oil, add rock sugar over medium heat, and cook until desired caramel color appears.",
                    img: "./images/recipes/braised_chicken_wings/step_4.jpg"
                },
                {
                    id: "5",
                    instruction: "Put chicken wings in the pan and mix them with caramel until the wings turn into golden color.",
                    img: "./images/recipes/braised_chicken_wings/step_5.jpg"
                },
                {
                    id: "6",
                    instruction: "Next, add cold water to submerge the chicken wings and several drops of dark soy sauce to give the wings a nice dark color.",
                    img: "./images/recipes/braised_chicken_wings/step_6.jpg"
                },
                {
                    id: "7",
                    instruction: "Add one and a half spoon of light soy sauce to give a mild salty flavor, one spoon of oyster sauce to increase the umami taste, and dried chili to give it a spicy flavor (optional).",
                    img: "./images/recipes/braised_chicken_wings/step_7.jpg"
                },              
                {
                    id: "8",
                    instruction: "Turn the heat to maximum and cover it with a lid to get thicker soup and softer texture of chicken wings. Thicker soup stays on wings better.",
                    img: "./images/recipes/braised_chicken_wings/step_8.jpg"
                },                
            ],
            ingredients: [
                {
                    name: "8-12 Chicken Wings",
                    special_tip: null
                },
                {
                    name: "4-5 pieces sliced Ginger",
                    special_tip: null
                },
                {
                    name: "Cooking wine",
                    special_tip: null
                },
                {
                    name: "1 bundle Green Onion",
                    special_tip: null
                },
                {
                    name: "Vegetable Oil",
                    special_tip: null
                },
                {
                    name: "6-7 pieces Rock Sugar",
                    special_tip: null
                },
                {
                    name: "8-10 pieces Chopped Garlic",
                    special_tip: null
                },
                {
                    name: "5 tablespoons light Soy Sauce",
                    special_tip: null
                },
                {
                    name: "2 tablespoons dark Soy Sauce",
                    special_tip: null
                },
                {
                    name: "4-5 dried Chilies",
                    special_tip: null
                },                 
            ],
            recipe_category: [
                {
                    name: "Lunch",
                    img: ""
                },
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Vegtarian",
                    img: ""
                },
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=EpH4ynsoLGI",
            author: "Mann Koner"
        },
        {
            id: "4",
            title: "Butter Chicken",
            description: "Delicious chicken curry fried with original butter",
            long_description: "Shahi Paneer is a preparation of cheese that is native to the Indian subcontinent and consists of thick cream, tomatoes and Indian spices. The creamy delicacy derived from Mughlai cuisine is made by emulsifying tomatoes, onions, ground cashews, clarified butter and cream into a curry, with cheese cubes and various spices. It is mainly eaten with traditional Indian bread such as Indian pancakes or naan, rice and bread. Paneer is derived from cheese in Persian, and Shahi is the royal name (referring to the imperial court of the Mughal Empire).",
            imageSrc: "./images/recipes/butter_chicken/final_dish.jpg",
            cook: "Manoj Bhuma",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 6,
            taste: "Spicy",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Finely chop onions and tomatoes. Mix cashews, green cardamom, cinnamon stick, red chilli, salt per taste and oil into a vessel. And leave it aside.",
                    img: "./images/recipes/butter_chicken/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Now add some salt and chilli powder(can use paprika for spice) to the chicken and mix it well so all the chicken pieces mix well in spices.",
                    img: ""
                },
                {
                    id: "3",
                    instruction: "Now fry these chicken pieces in a frying pan added with butter.  Fry until chicken turns golden brown colour. Stir the chicken for every 1-2 mins.",
                    img: "./images/recipes/butter_chicken/step_3.png"
                },
                {
                    id: "4",
                    instruction: "Meanwhile, you can move the tomato, onion vessel onto the stove and cook until this mixture turns soft. Stir the mixture every 1-2 mins.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "While the chicken turns golden brown if the tomato mixture turns to soften then move this mixture into a blender and make it fine paste(should turn yellow colour)",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Meanwhile, your chicken would turn golden brown. Move this chicken out of the frying pan and add butter(4-5 spoons) into the same pan.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "After the butter melts add the spices(garam masala, ginger garlic paste, Kashmiri chilli powder (for good colour and spice to your curry). Fry these spices for 1 minute. And add the blended mixture into this spices fried pan.",
                    img: "./images/recipes/butter_chicken/step_7.png"
                },
                {
                    id: "8",
                    instruction: "Turn your stove to medium and stir thoroughly. Check your spice and salt levels. Add some sugar if you feel spicy. Though not spicy I recommend adding sugar(1 tablespoon) for good flavour for your curry.",
                    img: ""
                },
                {
                    id: "9",
                    instruction: "Now fry these chicken pieces in a frying pan added with butter.  Fry until chicken turns golden brown colour. Stir the chicken for every 1-2 mins.",
                    img: ""
                },
                {
                    id: "10",
                    instruction: "Add some Kasuri methi stir well and mix the chicken pieces into this curry. After a good stir adds cream on top and stirs well until the cream and curry mix well.",
                    img: ""
                },
                {
                    id: "11",
                    instruction: "Leave this curry for 1-2 minutes on medium flame and add some more cream for good flavour. Stir it well and rest this curry for 2-3 minutes until the oil from your curry floats on top.",
                    img: "./images/recipes/butter_chicken/step_11.png"
                },
                {
                    id: "12",
                    instruction: "Turn off your flame and enjoy.",
                    img: ""
                }
            ],
            ingredients: [
                {
                    name: "1kg Boneless Chicken",
                    special_tip: null
                },
                {
                    name: "2 Onions",
                    special_tip: "Finely Chopped"
                },
                {
                    name: "2 Tomatoes",
                    special_tip: null
                },
                {
                    name: "4 Red Chillis",
                    special_tip: null
                },
                {
                    name: "5 Green Cardamom",
                    special_tip: null
                },
                {
                    name: "1-inch Cinnamon Stick",
                    special_tip: null
                },
                {
                    name: "10-12 units of Cashews",
                    special_tip: null
                },
                {
                    name: "Red chilli powder",
                    special_tip: "(preferred Kashmiri chilli powder for better colour)"
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "Oil",
                    special_tip: "(to fry chicken and spices)"
                },
                {
                    name: "35% Whipping Cream",
                    special_tip: null
                },
                {
                    name: "3 tablespoons of Garam Masala",
                    special_tip: null
                },
                {
                    name: "½ cup of Sugar",
                    special_tip: null
                },
                {
                    name: "1 tablespoon of Kasuri Methi ",
                    special_tip: null
                },
                {
                    name: "2 tablespoons of Ginger Paste ",
                    special_tip: null
                },
                {
                    name: "2 tablespoons of Garlic Paste",
                    special_tip: null
                },
                {
                    name: "Butter (as per taste)",
                    special_tip: null
                },
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=1sWgxKR1ZCc",
            author: "Gabriela Coba"
        },
        {
            id: "5",
            title: "Chicken Bara and Egg Bara",
            description: "Most popular dish of Nepal (Nepali pancakes)",
            long_description: "With being the most popular dish of Nepal, Chicken bara and egg bara are the classic foods of the Newar community of Nepal. It comes from a region called Bhaktapur, Nepal. This dish is quite similar to pancakes thereby it is called bara meaning Nepali pancakes. The key ingredients for this dish are Black Lentils, Yellow Split Peas, Chicken ground meat, and Eggs. One can have this dish for breakfast or lunch. It is exclusively made at the time of Yomari Punhi, a festival celebrated in December, and also on special occasions like weddings, birthdays, or festivals. The Nepalis eat this dish for good luck. The Newar cultures have so many traditional celebrations and festivals, where lots of people gather together to celebrate it with each other. Everyone brings their own home-cooked food, and chicken bara and egg bara are the essence during such celebrations. Now that this dish has become popular in Nepal, other cultures have also started cooking chicken bara and egg bara during special occasions.",
            imageSrc: "./images/recipes/chicken_bara_egg_bara/final_dish.jpg",
            cook: "Roshan Sapkota",
            cooking_time: "1 hr",
            difficulty_level: "Medium",
            number_of_portions: 2,
            taste: "Mild",
            country: "Nepal",
            countryIcon: "./images/country/nepal.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Take a large bowl, mix black lentils and yellow split peas. Add some water, let it soak, for at least 6 hours, and leave it overnight.",
                    img: "./images/recipes/chicken_bara_egg_bara/step_1.jpg"
                },
                {
                    id: "2",
                    instruction: "Next day, clean and rinse the mixture. Wash and rub the lentils a couple of times unless the outer layer will go away.",
                    img: ""
                },
                {
                    id: "3",
                    instruction: "Grind it finely to make a smooth paste. Add little water if necessary.",
                    img: ""
                },
                {
                    id: "4",
                    instruction: "Add salt and ginger garlic paste to the blended lentils. Stir it properly.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "The batter is ready. Next step is to add ingredients to ground meat.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Add chopped coriander, turmeric, salt, cumin, and ginger garlic paste with ground meat and mix it well.",
                    img: "./images/recipes/chicken_bara_egg_bara/step_6.jpg"
                },
                {
                    id: "7",
                    instruction: "To fry the batter, spread 3 tablespoons of oil in a heated pan. Slowly, with a ladle, pour half cup of batter into the pan and make a thin round shape.",
                    img: "./images/recipes/chicken_bara_egg_bara/step_7.jpg"
                },
                {
                    id: "8",
                    instruction: "For egg bara, add one egg on top of the patty and cook on a medium flame until it turns light brown in color. Turn it over and cook on the other side for 3 minutes. Egg bara is ready to serve.",
                    img: "./images/recipes/chicken_bara_egg_bara/step_8.jpg"
                },
                {
                    id: "9",
                    instruction: "For chicken bara, take a small piece of meat mixture and put it on top of the patty. Cook on a medium flame until it turns light brown. Turn the patty over and cook for 3 minutes. Chicken bara is ready to serve.",
                    img: ""
                },
                {
                    id: "10",
                    instruction: "Paper towels can be used to remove the excess oil if required.",
                    img: ""
                },                
            ],
            ingredients: [
                {
                    name: "¼ cup black lentils",
                    special_tip: null
                },
                {
                    name: "¼ cup yellow split Peas",
                    special_tip: null
                },
                {
                    name: "1/3 bowl Chicken ground meat",
                    special_tip: null
                },
                {
                    name: "2 Eggs",
                    special_tip: null
                },
                {
                    name: "2 tablespoons Ginger garlic paste",
                    special_tip: "(lentils batter)"
                },
                {
                    name: "3 tablespoons chopped Coriander",
                    special_tip: null
                },
                {
                    name: "3 tablespoons ginger garlic paste",
                    special_tip: "(Ground Meat)"
                },
                {
                    name: "3 tablespoons cooking oil",
                    special_tip: "(for each bara patty)"
                },
                {
                    name: "½ cumin powder",
                    special_tip: null
                },
                {
                    name: "½ turmeric powder",
                    special_tip: null
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
            ],
            recipe_category: [
                {
                    name: "Lunch",
                    img: ""
                },
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-veg",
                    img: ""
                },
                {
                    name: "",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=aJvfTUnu7fA",
            author: "Mann Koner"
        },
        {
            id: "6",
            title: "Chicken Korma",
            description: "The finger-licking appetizing gravy dish with chicken",
            long_description: "Are you looking for some tempting chicken recipe? Chicken/ Murgh Korma is probably the best choice. It was originated during the Mughal Era in the Indian subcontinent, an appetizing gravy dish, full of spices, which is now considered a very popular dish all around the world. Murgh Korma was mostly prepared in the Mughal court kitchens, back in the 16th century, and was even served to the Mughal emperor Shah Jahan. This dish is famous in countries like India, Pakistan, Persia, and Bangladesh. In India, chicken korma is prepared differently in different parts like the navratan korma, south Indian korma, and many more. Chicken Korma is specially made on the occasion of EID but can be made any time of the year. The gravy is made up of onions, different types of spices, ginger garlic paste, tomatoes (optional), and the marinated chicken. The finger-licking dish serves best with hot steamed basmati rice.",
            imageSrc: "./images/recipes/chicken_korma/final_dish.jpg",
            cook: "Harsh Khilnani",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 4,
            taste: "Mild Spicy",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Marinate the chicken with salt, turmeric powder, chili powder, garam masala, cumin powder, coriander powder, korma powder, yogurt, and ginger garlic paste. Leave it for 30 mins.",
                    img: "./images/recipes/chicken_korma/step_1.jpg"
                },
                {
                    id: "2",
                    instruction: "Heat oil on medium flame, add some desi ghee, then fry 2 chopped onions with cashews, green and red chili pieces, add salt to taste, red chili powder, and turmeric powder",
                    img: "./images/recipes/chicken_korma/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "Once the onions turn a bit golden brown then let the mixture cool down.",
                    img: "./images/recipes/chicken_korma/step_3.jpg"
                },
                {
                    id: "4",
                    instruction: "Grind the mixture, add some water to make a fine paste.",
                    img: "./images/recipes/chicken_korma/step_4.jpg"
                },
                {
                    id: "5",
                    instruction: "Take a pan, pour some oil, and fry the chicken until it leaves oil.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Add the paste to the chicken and let it boil for 20-25 minutes. Add water if required.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "Once the chicken is cooked, serve with hot basmati rice.",
                    img: ""
                },                                
            ],
            ingredients: [
                {
                    name: "8 Chicken drumsticks",
                    special_tip: null
                },
                {
                    name: "2 big Onions",
                    special_tip: null
                },
                {
                    name: "1 cup Yogurt",
                    special_tip: null
                },
                {
                    name: "2 tablespoons Ginger Garlic Paste",
                    special_tip: null
                },
                {
                    name: "50-60 ml Oil/Gheee",
                    special_tip: "(lentils batter)"
                },
                {
                    name: "2 tablespoons all dry Spices",
                    special_tip: null
                },
                {
                    name: "5-7 Cashews",
                    special_tip: null
                },
                {
                    name: "2-3 Green Chili",
                    special_tip: null
                },
                {
                    name: "2-3 Red Chili",
                    special_tip: null
                },
                {
                    name: "50 gm Korma Powder",
                    special_tip: null
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "Turmeric powder, red chili powder, garam masala, cumin powder, and coriander powder",
                    special_tip: "(as needed)"
                },
            ],
            recipe_category: [
                {
                    name: "Lunch",
                    img: ""
                },
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-veg",
                    img: ""
                },
                {
                    name: "",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=2lErvzNCc3o",
            author: "Mann Koner"
        },        
        {
            id: "7",
            title: "Chinese Tomato Egg Stir fry",
            description: "Represents a homely chinese meal and delicacy",
            long_description: "This recipe comes from the largest Asian country China. This simple dish represents in Chinese culture this dish represents a homely meal or delicacy. Every family has a version of this dish cooked according to their own preferences. Usually, this dish is accompanied by rice or noodles as a garnish. However, on this occasion, we chose rice as a side dish. The name of the dish comes from the main ingredients of the dish, tomatoes and eggs, as well as the traditional way of Chinese cooking, stir-fry. We chose this dish as it is a simple option and with ingredients that are easy to find in any supermarket, perfect for university students who want to try something new.",
            imageSrc: "./images/recipes/chinese_tomato_egg_stir_fry/final_dish.png",
            cook: "Xiaoling Chen",
            cooking_time: "15 mins",
            difficulty_level: "Easy",
            number_of_portions: 2,
            taste: "Mild",
            country: "China",
            countryIcon: "./images/country/china1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "1",
                    instruction: "Start by cutting tomatoes into small wedges and finely chop the green onion or scallion.",
                    img: "./images/recipes/chinese_tomato_egg_stir_fry/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Crack 4 eggs into a bowl and season with ¼ teaspoon salt, ¼ teaspoon white pepper, ½ teaspoon sesame oil, and 1 teaspoon Shaoxing Whine. Beat eggs for a minute.",
                    img: "./images/recipes/chinese_tomato_egg_stir_fry/step_2.png"
                },
                {
                    id: "3",
                    instruction: "Preheat the wok over medium heat until it just starts to smoke. Then add 2 tablespoons of oil and immediately add the eggs. Scramble the eggs and remove from the wok immediately. St aside.",
                    img: "./images/recipes/chinese_tomato_egg_stir_fry/step_3.png"
                },
                {
                    id: "4",
                    instruction: "Add one more tablespoon oil to the wok, turn up the heat to high, and add the tomatoes and scallions. Stir-fry for one minute, and then add two teaspoons of sugar, and ½ teaspoon salt. Add the cooked eggs.",
                    img: "./images/recipes/chinese_tomato_egg_stir_fry/step_4.png"
                },
                {
                    id: "5",
                    instruction: "Mix everything together, cover the wok, and cook for one to two minutes, until the tomatoes are completely softened.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Uncover, and continue to stir-fry over high heat until the sauce thickens to your liking. Serve with steamed rice!",
                    img: "./images/recipes/chinese_tomato_egg_stir_fry/step_6.png"
                }                
            ],
            ingredients: [
                {
                    name: "1 ½ tablespoons vegetable oil",
                    special_tip: null
                },
                {
                    name: "4 eggs",
                    special_tip: "(beaten)"
                },
                {
                    name: "2 big tomatoes or 4 smalls to medium tomatoes",
                    special_tip: "(chopped)"
                },
                {
                    name: "1 teaspoon salt",
                    special_tip: null
                },
                {
                    name: "½ teaspoon sesame oil",
                    special_tip: null
                },
                {
                    name: "¼ teaspoon white pepper",
                    special_tip: null
                },
                {
                    name: "1 teaspoon Shaoxing wine",
                    special_tip: null
                },
                {
                    name: "1 tablespoon granular sugar",
                    special_tip: "(preferred Kashmiri chilli powder for better colour)"
                },
                {
                    name: "Chopped green onion or scallion for garnish",
                    special_tip: "(optional)"
                },
                {
                    name: "Steamed rice to serve with",
                    special_tip: null
                },               
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=InaS65R6WmE",
            author: "Gabriela Coba"
        },
        {
            id: "8",
            title: "Dal Makhani",
            description: "North Indian curry loaded with cream and butter",
            long_description: "India is a country where one can discover a variety of dishes. When speaking about Punjab, a northern state of India, one of the famous dishes is Dal Makhani which is loaded with cream and butter. It is a traditional dish of the north region of India which comes with its unique taste and form. It is a kind of a rich and flavourful Indian curry coming straight from the Punjabi cuisine. The dish was originally made by the Punjabis in the western parts of the Punjab, which is currently a part of Pakistan. The Punjabis used to sell this Dal with a bread called Roti in their Dhabas ( roadside restaurants). Dal Makhani is a contemporary dish made from the staple foods of the Indian cuisine, such as Urad Dal (Black lentils) and Rajma (kidney beans) alongwith cream and butter. It serves best with roti or garlic naan, but it also tastes delicious with steamed rice. In India, people love to have this flavorsome dish during special occasions,  festivals and even at weddings. If you visit India, you can find this dish at every restaurant in the country.",
            imageSrc: "./images/recipes/dal_makhani/final_dish.jpg",
            cook: "Baljeet Kaur",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 6,
            taste: "Mild Spicy",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "title",
                    instruction: "Pressure cooking the Dal",
                    img: "",
                },
                {
                    id: "1",
                    instruction: "Take a large bowl, mix 1 cup of black urad dal with ½ cup of rajma (kidney beans), add enough water, and let it soak overnight (for at least 8 hours).",
                    img: "./images/recipes/dal_makhani/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Rinse the overnight soaked dal with some freshwater, transfer it into a cooker, add 3 cups of water, and add some salt to taste.",
                    img: "./images/recipes/dal_makhani/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "After that, close the lid of the cooker and set the flame on low-medium. Wait for 5 whistles or till the dal is boiled properly.",
                    img: "./images/recipes/dal_makhani/step_3.png"
                },
                {
                    id: "title",
                    instruction: "Preparing Dal Makhani step by step",
                    img: "",
                },
                {
                    id: "4",
                    instruction: "Take a large wok and add a tablespoon of ghee (clarified butter). Simmer it on low flame until it turns fragrant.",
                    img: "./images/recipes/dal_makhani/step_4.png"
                },
                {
                    id: "5",
                    instruction: "Now, add the roughly chopped garlic and green chilies, sauté them until they change color, and the raw smell disappears completely.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Add tomato puree into the wok, and let it cook till the oil or ghee gets separated. Simultaneously, add turmeric, coriander powder, cumin powder, red chili powder, and salt to taste. Let the spices cook for one minute.",
                    img: "./images/recipes/dal_makhani/step_6.png"
                },
                {
                    id: "7",
                    instruction: "Once the spices start releasing the oil from the sides, add the boiled dal and give it a good stir. After that, add 1 cup of water or as required to adjust the consistency.",
                    img: "./images/recipes/dal_makhani/step_7.png"
                },
                {
                    id: "8",
                    instruction: "Let the Dal cook for 15 minutes or until it absorbs the flavor and turns thick.",
                    img: ""
                },
                {
                    id: "9",
                    instruction: "Now, add 2 tablespoons of cream on top to get the creamy texture. According to your preference, you can add more cream or skip it.",
                    img: "./images/recipes/dal_makhani/step_9.png"
                },
                {
                    id: "10",
                    instruction: "To give it more flavor, add some freshly chopped coriander leaves and ginger julienne on top.",
                    img: "./images/recipes/dal_makhani/step_10.png"
                },
            ],
            ingredients: [
                {
                    name: "2 cups black Urad dal",
                    special_tip: null
                },
                {
                    name: "½ cup Rajma",
                    special_tip: "(kidney beans)"
                },
                {
                    name: "2 tablespoons Butter",
                    special_tip: null
                },
                {
                    name: "2 tablespoons of roughly chopped Garlic and Green Chilies",
                    special_tip: null
                },
                {
                    name: "2-3 Tomatoes",
                    special_tip: "(for puree)"
                },
                {
                    name: "2 tablespoons Red Chili Powder",
                    special_tip: null
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "3-4 black Peppercorns",
                    special_tip: null
                },
                {
                    name: "½ tablespoon Cumin Seeds",
                    special_tip: null
                },
                {
                    name: "1 - ½ cup of lukewarm Water",
                    special_tip: null
                },
                {
                    name: "2-3 tablespoon of cream",
                    special_tip: null
                },
                {
                    name: "½ tablespoon Coriander Powder",
                    special_tip: null
                },  
                {
                    name: "1 tablespoon Ghee or any Vegetable Oil",
                    special_tip: null
                },  
                {
                    name: "1-2 tablespoon(s) Salted Butter",
                    special_tip: null
                },  
                {
                    name: "1 tablespoon ginger juliennes",
                    special_tip: "(optional)"
                },              
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Curry",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=UOoZeZHxri0",
            author: "Mann Koner"
        },
        {
            id: "9",
            title: "Efo Riro",
            description: "Tasty stew of meat and greens from Nigeria",
            long_description: "Efo Riro is a tasty stew of meat and greens, native to Nigeria's Yorubas, one of Africa's largest ethnic groups. In various parts of Africa, including Cameroon and Ghana, there are various versions of the green leafy vegetable stew. However, among the Yoruba tribe, this recipe is usually made in Nigeria. It is generally made with Efo Shoko (spinach from Lagos) or Efo Tete (spinach from Africa), and Amaranth genus members. These leaves are soft and have a fair spinach flavour. However, as it is not readily available, this green leafy vegetable can be substituted with spinach or other vegetables. It is an entirely delicious dish. If you are a vegetarian or are not very fond of certain types of meats, do not worry since you can omit the use of meats or change it for Tofu or your favourite protein replacement. This dish serves best with steamed rice.",
            imageSrc: "./images/recipes/efo_riro/final_dish.jpeg",
            cook: "Temitope Olaiya",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 2,
            taste: "Mild Spicy",
            country: "Nigeria",
            countryIcon: "./images/country/nigeria.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "First, put chopped bell pepper and little water in a blender, and blend properly to get the red flakes",
                    img: "./images/recipes/efo_riro/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Next, in a pressure cooker, add cow skin, beef tripe, seasoning cubes, onions, ginger, salt, and some water. Leave it for 20-30 minutes or 7 pressure cooks.",
                    img: ""
                },
                {
                    id: "3",
                    instruction: "To make the soup, take  a pan and put it on a medium flame. Pour some red palm oil, and wait for the oil to change colour.",
                    img: ""
                },
                {
                    id: "4",
                    instruction: "Add some finely chopped peppers and onions, mix it properly, and let it fry for 2-3 minutes.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "Next, add some locust beans, crayfish, and spinach, one by one, and mix all the ingredients properly.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Add some salt and seasoning cubes into the mixture and keep stirring regularly.",
                    img: "./images/recipes/efo_riro/step_6.jpeg"
                },
                {
                    id: "7",
                    instruction: "Now, pour the cooked meat into the pan, and mix all the ingredients properly.",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "Keep stirring the ingredients for 5-10 minutes. Once the dish is ready, serve it with steamed rice.",
                    img: "./images/recipes/efo_riro/step_8.jpeg"
                },               
            ],
            ingredients: [
                {
                    name: "1 bag Spinach",
                    special_tip: null
                },
                {
                    name: "2 Red Bell Pepper",
                    special_tip: null
                },
                {
                    name: "1 Scotch Bonnet",
                    special_tip: null
                },
                {
                    name: "1 Onion",
                    special_tip: null
                },
                {
                    name: "3-4 Garlic Cloves",
                    special_tip: null
                },
                {
                    name: "3-4 Ginger pieces",
                    special_tip: null
                },
                {
                    name: "Locust beans",
                    special_tip: null
                },
                {
                    name: "Crayfish",
                    special_tip: null
                },
                {
                    name: "Beef Tripe",
                    special_tip: null
                },
                {
                    name: "Cow Skin",
                    special_tip: null
                },
                {
                    name: "Seasoning cubes",
                    special_tip: null
                },  
                {
                    name: "Red Palm Oils",
                    special_tip: null
                },  
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },                
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Curry",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=AKBNG2GuUrA",
            author: "Gabriela Coba and Mann Koner"
        },
        {
            id: "10",
            title: "Jollof Rice and Gizdodo",
            description: "A delicious combination of chicken, tomato, rice",
            long_description: "A delicious combination of chicken, tomato, rice, curry and other extraordinary flavours will take you to Africa with this dish called Jollof Rice and Gizdodo. It is a popular dish eaten in most parts of West Africa, most notably Ghana, Senegal, The Gambia, and Nigeria. There are numerous hypotheses as to how Jollof Rice came to be. It is necessary to remember that the meal has spread across West Africa, and there is no documented history to show the ownership of one country over the meal. If you do not want to miss out on trying this little piece of Africa, don't take off from this recipe that our dear Emmanuel Ibharalu, an African international student from Lakehead University, will delight us with his recipe.",
            imageSrc: "./images/recipes/jollof_rice_and_gizdodo/final_dish.jpg",
            cook: "Emmanuel Ibharalu",
            cooking_time: "1 hr 30 mins",
            difficulty_level: "Medium",
            number_of_portions: 6,
            taste: "Mild Spicy",
            country: "Nigeria",
            countryIcon: "./images/country/nigeria.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Blend the tomatoes, bell peppers, scotch bonnet peppers, onions, garlic(optional) till smooth. Add little water to aid blending.",
                    img: "./images/recipes/jollof_rice_and_gizdodo/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Boil the blend till dry of water, then pour in a bowl and set aside.",
                    img: ""
                },
                {
                    id: "3",
                    instruction: "Pour ¾ cup of oil in a pot, add chopped onions and tomato paste, fry till the paste is runny(loose), 5 mins.",
                    img: ""
                },
                {
                    id: "4",
                    instruction: "Wash 4 cups of rice, removing as much starch as possible and set aside.",
                    img: "./images/recipes/jollof_rice_and_gizdodo/step_4.png"
                },
                {
                    id: "5",
                    instruction: "Add the blend to the pot with seasoning cubes, salt and pepper, garlic powder (optional), thyme, curry powder and bay leaves. Leave to cook on medium-low heat for 10 mins till oil sets on top of the stew.",
                    img: "./images/recipes/jollof_rice_and_gizdodo/step_5.png"
                },
                {
                    id: "6",
                    instruction: "Add the washed rice to the pot and mix well, then add chicken broth (optional) or water to allow for proper cooking and season for taste. Add 1 or 2 more bay leaves.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "Reduce heat to low and line the cover with aluminum foil and cover for 25mins.",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "Stir the rice well and leave for another 15- 20mins, and your Jollof rice is ready to be served.",
                    img: "./images/recipes/jollof_rice_and_gizdodo/step_8.png"
                },              
            ],
            ingredients: [
                {
                    name: "Tomatoes",
                    special_tip: null
                },
                {
                    name: "2 tbsp Tomato paste",
                    special_tip: null
                },
                {
                    name: "2 Bell peppers",
                    special_tip: null
                },
                {
                    name: "2 Onions",
                    special_tip: null
                },
                {
                    name: "11-2 Scotch bonnet peppers,",
                    special_tip: null
                },
                {
                    name: "Chicken gizzards",
                    special_tip: null
                },
                {
                    name: "1 Garlic",
                    special_tip: "(cloves/powder)"
                },
                {
                    name: "1 Seasoning cubes",
                    special_tip: null
                },
                {
                    name: "2 Bay leaves",
                    special_tip: null
                },
                {
                    name: "1-3 tbsp Curry powder",
                    special_tip: null
                },
                {
                    name: "Vegetable oil",
                    special_tip: null
                },
                {
                    name: "1-3 tbsp Thyme",
                    special_tip: null
                },
                {
                    name: "2-3 cups of Rice",
                    special_tip: null
                },
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Curry",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=oFsLQ0QnH9M",
            author: "Gabriela Coba"
        },
        {
            id: "11",
            title: "Llapingachos",
            description: "fresh vegetables and chorizo with a peanuts sauce!",
            long_description: "Arriving in South America we find Ecuador, a small country but without a doubt with dishes full of flavour and culture. For this occasion, we will prepare the famous Llapingachos, a dish based on potatoes, chorizo and fresh vegetables bathed with a delicious sauce made from peanuts. We invite you to dare to try new flavours and to bring out your culinary skills to surprise your friends with these international dishes and the best part is that they are very delicious and easy to prepare.",
            imageSrc: "./images/recipes/llapingachos/final_dish.jpg",
            cook: "Paul Gonzalo Benalcazar Vergara",
            cooking_time: "30 mins",
            difficulty_level: "Easy",
            number_of_portions: 4,
            taste: "Mild",
            country: "Ecuador",
            countryIcon: "./images/country/ecuador.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Peel and cut the potatoes.",
                    img: "./images/recipes/llapingachos/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Boil the potatoes until soft for around 20 min medium heat",
                    img: ""
                },
                {
                    id: "3",
                    instruction: "Once the potatoes are soft, mash the potatoes, add the garlic previous cut in small pieces, and salt as you wish.",
                    img: "./images/recipes/llapingachos/step_3.png"
                },
                {
                    id: "4",
                    instruction: "Make small golf size ball with the mashed potato.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "Make a little hole in the middle of the small potatoes and fill the grated cheese and flatted it to shape a tortilla.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Cook the tortilla on a hot griddle unto browned on each side to medium heat, and be careful to the tortilla since it became delicate.",
                    img: "./images/recipes/llapingachos/step_6.png"
                },
                {
                    id: "7",
                    instruction: "Serve with the sunny side up egg, peanut sauce, tomato, and pickled onion, avocado slices and hot sauce (optional).",
                    img: "./images/recipes/llapingachos/step_7.png"
                }                
            ],
            ingredients: [
                {
                    name: "2 pounds of Russet potatoes, peeled and cut in chunks",
                    special_tip: null
                },
                {
                    name: "1 avocado",
                    special_tip: null
                },
                {
                    name: "4 eggs",
                    special_tip: null
                },
                {
                    name: "2 chorizos pork sausage",
                    special_tip: null
                },
                {
                    name: "½ lettuce",
                    special_tip: null
                },
                {
                    name: "1 onion",
                    special_tip: null
                },
                {
                    name: "1 garlic",
                    special_tip: null
                },
                {
                    name: "1 tomato",
                    special_tip: null
                },
                {
                    name: "1 lemon",
                    special_tip: null
                },
                {
                    name: "100 gm soft mozzarella cheese",
                    special_tip: null
                }, 
                {
                    name: "Vegetable oil",
                    special_tip: null
                }, 
                {
                    name: "50 gm of peanuts",
                    special_tip: null
                }, 
                {
                    name: "1 cup of milk ",
                    special_tip: null
                }, 
                {
                    name: "1 teaspoon ground achiote",
                    special_tip: "(optional)"
                },
                {
                    name: "Peanut gravy",
                    special_tip: "(for serving)"
                },
                {
                    name: "Pickled tomato and onion ",
                    special_tip: "(for serving)"
                },
                {
                    name: "Avocado slices and cutted lettuce",
                    special_tip: "(for serving)"
                },
                {
                    name: "Fried eggs",
                    special_tip: "(for serving)"
                },
                {
                    name: "Hot sauce (optional) ",
                    special_tip: "(for serving)"
                }
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=o-2BlvEdkFQ",
            author: "Gabriela Coba"
        },
        {
            id: "12",
            title: "Sauteed Potato, Eggplant, and Pepper",
            description: "Vegetarian dish with delicious and full of flavour!",
            long_description: "Going to China a country that undoubtedly has diverse gastronomy. For this case, we bring you a vegetarian dish but without a doubt delicious and full of flavour! Sauteed Potato, eggplant, and pepper is a dish based on potato, eggplant, pepper, and lots of flavours. We are sure that you will love it for its excellent flavour. Dare to try something new and delicious.",
            imageSrc: "./images/recipes/sauteed_potato_eggplant_and_pepper/final_dish.jpg",
            cook: "Bowen Jiang",
            cooking_time: "10 mins",
            difficulty_level: "High",
            number_of_portions: 3,
            taste: "Mild Spicy",
            country: "China",
            countryIcon: "./images/country/china1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "1",
                    instruction: "Peel potatoes and cut into big pieces and boil them for 5 minutes.",
                    img: "./images/recipes/sauteed_potato_eggplant_and_pepper/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Cut eggplants into big pieces and add a little vinegar (optional, to keep the eggplants’ surface colour) and some corn starch and stir.",
                    img: "./images/recipes/sauteed_potato_eggplant_and_pepper/step_2.png"
                },
                {
                    id: "3",
                    instruction: "Cut peppers into pieces.",
                    img: "./images/recipes/sauteed_potato_eggplant_and_pepper/step_3.png"
                },
                {
                    id: "4",
                    instruction: "Put some corn starch into the boiled potatoes and stir.",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "Deep-fry the potato and eggplant pieces in 160°C oil for about 5 mins till they turn golden add pepper pieces into the pot and keep frying for 2 minutes.",
                    img: "./images/recipes/sauteed_potato_eggplant_and_pepper/step_5.png"
                },
                {
                    id: "6",
                    instruction: "Turn the stove to medium, add a little oil. Add some minced garlic into the pot when the oil is about 160°C and stir.",
                    img: ""
                },   
                {
                    id: "7",
                    instruction: "Add 3g soy sauce, 1g superior dark soy sauce, 5g oyster flavoured source, and 8g water and stir, then add some salt and sugar. Mix 1/3 cup corn starch with 200ml water thoroughly and add it into the pot.",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "Add fried potatoes, eggplants, and peppers into the pot, then add chicken essence and stir-fry for about 1 minute.",
                    img: "./images/recipes/sauteed_potato_eggplant_and_pepper/step_8.png"
                },                
            ],
            ingredients: [
                {
                    name: "2 Potatoes",
                    special_tip: null
                },
                {
                    name: "2 Long eggplants",
                    special_tip: null
                },
                {
                    name: "2 Peppers",
                    special_tip: null
                },
                {
                    name: "Minced garlic",
                    special_tip: null
                },
                {
                    name: "Oil",
                    special_tip: null
                },
                {
                    name: "3g Soy sauce",
                    special_tip: null
                },   
                {
                    name: "1g Superior dark soy sauce",
                    special_tip: null
                },
                {
                    name: "5g Oyster flavoured source",
                    special_tip: null
                },   
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "Sugar ",
                    special_tip: "(as per taste)"
                },
                {
                    name: "Chicken essence ",
                    special_tip: "(optional)"
                }, 
                {
                    name: "Corn starch ",
                    special_tip: null
                }, 
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=JY7cVCGvUys",
            author: "Gabriela Coba"
        }, 
        {
            id: "13",
            title: "Shahi Paneer",
            description: "Creamy delicacy derived from Mughlai cuisine",
            long_description: "Shahi Paneer is a preparation of cheese that is native to the Indian subcontinent and consists of thick cream, tomatoes and Indian spices. The creamy delicacy derived from Mughlai cuisine is made by emulsifying tomatoes, onions, ground cashews, clarified butter and cream into a curry, with cheese cubes and various spices. It is mainly eaten with traditional Indian bread such as Indian pancakes or naan, rice and bread. Paneer is derived from cheese in Persian, and Shahi is the royal name (referring to the imperial court of the Mughal Empire).",
            imageSrc: "./images/recipes/shahi_paneer/final_dish.png",
            cook: "Hitesh Kumar",
            cooking_time: "40 mins",
            difficulty_level: "Medium",
            number_of_portions: 2,
            taste: "Mild Spicy",
            country: "India",
            countryIcon: "./images/country/india1.png",
            category: "Vegetarian",
            steps: [
                {
                    id: "1",
                    instruction: "On low-medium heat, place a pressure cooker or a pan.",
                    img: ""
                },
                {
                    id: "2",
                    instruction: "Add almonds, cashews, chopped tomato and chopped onions to the pan/cooker and fry for about 2 minutes and keep stirring.",
                    img: "./images/recipes/shahi_paneer/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "Add one glass of water. Stir and close the cooker lid or cover the pan with a lid.",
                    img: ""
                },
                {
                    id: "4",
                    instruction: "Let the mixture boil for 2 whistles or 5-7 minutes while using a pan. ",
                    img: ""
                },
                {
                    id: "5",
                    instruction: "Take the lid off and transfer the contents to a blending jar. Stock can be kept aside for later use.",
                    img: ""
                },
                {
                    id: "6",
                    instruction: "Blend the contents to get a smooth mix. The mixture is then kept aside.",
                    img: ""
                },
                {
                    id: "7",
                    instruction: "Place a pan on low to medium heat, add cooking oil & let the oil heat for 30 seconds.",
                    img: ""
                },
                {
                    id: "8",
                    instruction: "Add whole spices to the pan and fry for 10 seconds on medium heat.",
                    img: ""
                },
                {
                    id: "9",
                    instruction: "Add Turmeric powder & red chilli powder to the pan and mix. Add the blended paste, cumin powder, coriander powder and stir properly.",
                    img: ""
                },
                {
                    id: "10",
                    instruction: "Cover the pan with a lid and let it cook for around 3 minutes. Keep stirring in.",
                    img: ""
                },
                {
                    id: "11",
                    instruction: "Turn off the flame and add whisked curd/yogurt to fried paste and stir it properly for 1 minute. Turn on the heat to medium and cook for another minute.",
                    img: ""
                },
                {
                    id: "12",
                    instruction: "Add ½ glass of water or stock, salt to taste and stir. Cover the pan and let it cook for 2 minutes on medium heat.",
                    img: ""
                },
                {
                    id: "13",
                    instruction: "Add cut cubes of paneer and 2 tablespoons of cream and stir gently. Add Garam masala powder, cover the pan and cook for another 2 minutes.",
                    img: ""
                },
                {
                    id: "14",
                    instruction: "Turn off the heat. Shahi Paneer is ready to serve. Garnish with almonds and a little cream and relish the aroma and taste.",
                    img: "./images/recipes/shahi_paneer/step_14.jpg"
                }
            ],
            ingredients: [
                {
                    name: "200-250 grams Paneer ",
                    special_tip: null
                },
                {
                    name: "⅓ cup Curd or Plain yogurt",
                    special_tip: null
                },
                {
                    name: "5-6 cloves of Garlic",
                    special_tip: "(peeled)"
                },
                {
                    name: "1-inch ginger (peeled)",
                    special_tip: null
                },
                {
                    name: "8-10 units of Cashews",
                    special_tip: null
                },
                {
                    name: "4-5 units of Almonds",
                    special_tip: null
                },
                {
                    name: "1 medium Onion",
                    special_tip: "(chopped)"
                },
                {
                    name: "2 medium Tomato",
                    special_tip: "(chopped)"
                },
                {
                    name: "2 Cloves",
                    special_tip: null
                },
                {
                    name: "½ inch Cinnamon Stick ",
                    special_tip: null
                },
                {
                    name: "2 units Green Cardamom ",
                    special_tip: null
                },
                {
                    name: "1 unit Black Cardamom",
                    special_tip: null
                },
                {
                    name: "⅓ teaspoon Turmeric Powder",
                    special_tip: null
                },
                {
                    name: "½ teaspoon Red Chilli Powder ",
                    special_tip: null
                },
                {
                    name: "⅓ teaspoon Coriander Powder ",
                    special_tip: null
                },
                {
                    name: "⅓ teaspoon Cumin Powder",
                    special_tip: null
                },
                {
                    name: "⅓ teaspoon Garam Masala",
                    special_tip: null
                },
                {
                    name: "Salt",
                    special_tip: "(as per taste)"
                },
                {
                    name: "2 tablespoon Cooking oil",
                    special_tip: null
                },
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Vegetarian",
                    img: ""
                },                
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=5Z4_ug2XRCI",
            author: "Gabriela Coba"
        },
        {
            id: "14",
            title: "Shanghai Mixed Rice",
            description: "Delicious fusion of rice, vegetables and sausage",
            long_description: "From southeast China-Shanghai and its surrounding areas we have Shanghai Mixed Rice a homey dish but full of culture and flavour. This dish is based on a delicious combination of rice, vegetables and sausage that we are sure you will love, and the best part is that it is a simple and quick recipe to prepare.  This dish comes from a time of scarcity in China and it is for this reason that this dish has a meaning of union and it is customary to eat it at home and usually with the family. If you are not from China, we invite you to try it and bring a little of this family tradition to your kitchen and if you are a Chinese citizen outside of your country, we have this perfect recipe to help you if you feel homesick.",
            imageSrc: "./images/recipes/shanghai_mixed_rice/final_dish.jpg",
            cook: "Danning Jiang",
            cooking_time: "15-20 mins",
            difficulty_level: "Easy",
            number_of_portions: 2,
            taste: "Mild",
            country: "China",
            countryIcon: "./images/country/china1.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Cut the carrot, the mushrooms, the sausage and the green vegetables into pieces and set them apart.",
                    img: "./images/recipes/shanghai_mixed_rice/step_1.jpg"
                },
                {
                    id: "2",
                    instruction: "Add the rice and water into the rice cooker pot (ratio of rice and water: 1:1.2). You can use your finger to measure it, letting water just over your first knuckle.",
                    img: "./images/recipes/shanghai_mixed_rice/step_2.jpg"
                },
                {
                    id: "3",
                    instruction: "Then add all the cuts into the rice cooker. Start to cook by pressing the cook button.",
                    img: ""
                },                
            ],
            ingredients: [
                {
                    name: "1 Cup of white rice",
                    special_tip: null
                },
                {
                    name: "2 Cups of water",
                    special_tip: null
                },
                {
                    name: "1 Smoked sausage",
                    special_tip: null
                },
                {
                    name: "2 Mushrooms",
                    special_tip: null
                },
                {
                    name: "1 Carrot",
                    special_tip: null
                },
                {
                    name: "Green vegetables of your choice",
                    special_tip: "(Shanghai Bok choy, or snow peas or other green vegetables)"
                },                
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=QSZmTq8UWV0",
            author: "Gabriela Coba"
        },
        {
            id: "15",
            title: "Sichuan Boiled Fish",
            description: "For lovers of spicy and seafood",
            long_description: "For lovers of spicy and seafood we have a recipe that we are sure you will enjoy, it is an Asian dish based on fish and a delicious sauce. This dish comes from Chong Qing, a city located in southwest China. The Sichuan spicy boiled fish recipe is said to have been invented by a Sichuan chef. And the boiled pork slices were the original edition. But a good friend of his visited him at home one time, but this friend did not eat pork. So, he made a boiled fillet of fish. The dish will then become one of Sichuan's most popular dishes ever! It is usually accompanied with beer and / or rice. If you feel like it, do not worry, here we are going to show you how to prepare it.",
            imageSrc: "./images/recipes/sichuan_boiled_fish/final_dish.png",
            cook: "Wenhan Zhang",
            cooking_time: "45 mins",
            difficulty_level: "Medium",
            number_of_portions: 2,
            taste: "Spicy",
            country: "China",
            countryIcon: "./images/country/china1.png",
            category: "Non-veg",
            steps: [
                {
                    id: "1",
                    instruction: "Prepare ingredients: basa fillet, dried peppers, vegetables, ham (optional)",
                    img: "./images/recipes/sichuan_boiled_fish/step_1.png"
                },
                {
                    id: "2",
                    instruction: "Cut the basa fillet into thin sections, and add the cut fish fillets to the marinade and cooking wine in the seasoning bag. Knead thoroughly with your hands.",
                    img: "./images/recipes/sichuan_boiled_fish/step_2.png"
                },
                {
                    id: "3",
                    instruction: "Wash the vegetables and put them in a bowl. Cut the ham into slices. Cut the dried chili into sections.",
                    img: "./images/recipes/sichuan_boiled_fish/step_3.png"
                },
                {
                    id: "4",
                    instruction: "Bring the water to a boil in the pot, add the vegetables, and take out after 30 seconds. Put it in the bowl to bottom.",
                    img: ""
                }, 
                {
                    id: "5",
                    instruction: "Pour the oil in the pot, add the oil bag, stir fry and add 800 ml of boiling water.",
                    img: ""
                }, 
                {
                    id: "6",
                    instruction: "Add the marinated fish fillets one by one, and slowly stir the fish fillets after they boil over high heat. After two minutes, remove the fish fillets and place them on top of the vegetables.",
                    img: "./images/recipes/sichuan_boiled_fish/step_6.png"
                }, 
                {
                    id: "7",
                    instruction: "Add the chopped ham, cook for a minute and remove.",
                    img: "./images/recipes/sichuan_boiled_fish/step_7.png"
                }, 
                {
                    id: "8",
                    instruction: "Pour the soup from the pot into the bowl.",
                    img: ""
                }, 
                {
                    id: "9",
                    instruction: "Pour the oil in another pot, when the oil is 40% hot, add the dried chili and chili seasoning bag to the pot, and fry it until fragrant. Pour into a bowl with fish fillets.",
                    img: "./images/recipes/sichuan_boiled_fish/step_9.png"
                }, 
                {
                    id: "10",
                    instruction: "Sprinkle with chopped green onions to complete this dish.",
                    img: "./images/recipes/sichuan_boiled_fish/step_9.png"
                },
            ],
            ingredients: [
                {
                    name: "1 Cup of white rice",
                    special_tip: null
                },
                {
                    name: "2 Cups of water",
                    special_tip: null
                },
                {
                    name: "1 Smoked sausage",
                    special_tip: null
                },
                {
                    name: "2 Mushrooms",
                    special_tip: null
                },
                {
                    name: "1 Carrot",
                    special_tip: null
                },
                {
                    name: "Green vegetables of your choice",
                    special_tip: "(Shanghai Bok choy, or snow peas or other green vegetables)"
                },                
            ],
            recipe_category: [
                {
                    name: "Dinner",
                    img: ""
                },
                {
                    name: "Non-Veg",
                    img: ""
                },
                {
                    name: "High Protien",
                    img: ""
                }
            ],
            recipe_images: [
                "image1",
                "image2",
                "image3"
            ],
            video_url: "https://www.youtube.com/watch?v=alD_DK512Ng",
            author: "Gabriela Coba"
        },        
    ]

export const countries = [
    {
        name: "India",
        countryImg: "./images/country/india.png",
        cultureContent: "India has a rich cuisine that has developed over centuries with the confluence of multiple cultures that came to live in India and its neighbors. Traditional food practices in the country are primarily based on a holistic nutritional approach called Ayurveda, which sorts food into various categories, called Vata, Pitta, and Kapha, based on nutritional values. A prescribed balanced Indian meal is considered to have a balance of sweet, sour, salty, bitter, spicy, and astringent flavors. food from Eastern regions has the ideal combination of sweet and spicy aromas; India also has a diverse regional food. Such as food in the West is generally calorie-rich with high nutritional value; the bottle gourd kofta dish is a great example of Gujarati cuisine from the west. Food in the North has many characteristics of the Central Asian, Persian, and Afghan style of cooking.",
        recipes: [
            'butter_chicken',
            'dal_makhani',
            'Shahi Paneer',
        ]
    },
    {
        name: "China",
        countryImg: "./images/country/china.jpg",
        cultureContent: "Cooking was only recognized as an art during the Chou dynasty. Before that, the people learnt to hunt and fish then grow crops. Slowly, China evolved its cooking method making some gracious meals. Chinese food has one of the world's greatest and most varied cuisines. Chinese believe to have their meals with friends or family.A Chinese meal is divided into two parts: staple food (typically rice, noodles, or steamed buns) and ts'ai (vegetable and meat dishes). In China, there's a lot of different cuisines. Each province has its own particular type of cooking. There is Beijing cuisine, Hunan or Hubai cuisine, Shanghai cuisine, Szechwan cuisine, Cantonese cuisine, Hakka cuisine1, etc., and among these Szechwan and Cantonese are the most well-known cuisine.",
        recipes: [
            'butter_chicken',
            'dal_makhani',
            'Shahi Paneer',
        ]
    },
    {
        name: "Ecuador",
        countryImg: "./images/country/ecuador.png",
        cultureContent: "Derived from its long and rich history, Ecuadorian culture includes an appropriate mix of indigenous practices alongside European colonial influence. Ecuador is a land of enormous cultural diversity, unique customs, and traditions. Ecuador has 4 different regions, all with very characteristic food; therefore, there is no dish representing its entire culture. Ecuadorian food consists mainly of soups and stews, corn pancakes, rice, eggs, and vegetables. Seafood is excellent, even in the highlands. Local specialties include ceviche, seafood prepared in lemon juice, and guinea pig. Whether you go, rice is a staple of the diet and soups and stews are very popular. Tubers, such as potatoes and cassava, are also an essential part of Ecuadorian gastronomy.",
        recipes: [
            'butter_chicken',
            'dal_makhani',
            'Shahi Paneer',
        ]
    },
    {
        name: "Nepal",
        countryImg: "./images/country/nepal.png",
        cultureContent: "In Nepal, Dal, Bhat, and Tarkari are the main food (when translated to English it becomes like Lentils, Rice, and Vegetables), and the most popular dish is Momos (dumplings). In hills and in rural areas where rice is very costly, Nepalese replace rice with 'dhedo' made from meal and water or butter. In Nepal, cows are the holy animal. Nepalese do not consume beef products. The Indian and Tibetan cooking styles have inspired Nepali cuisine. Some of the best-loved dishes of Nepal are: Sel roti, which is a deep-fried, donut-shaped, and less sweet snack; Gundruk, which is made by fermenting leafy green vegetables and commonly made into a pickle. It is slightly mushroomy and quite salty; Bara a popular Newari snack that is kind of like a thick savory rice-flour pancake; Momos, usually called Tibetan dumplings, they are rice paper wrappers are stuffed with finely chopped vegetables, minced buffalo or chicken and are steamed, deep-fried or added to a spicy soup.",
        recipes: [
            'butter_chicken',
            'dal_makhani',
            'Shahi Paneer',
        ]
    }, 
    {
        name: "Nigeria",
        countryImg: "./images/country/nigeria.png",
        cultureContent: "Nigerian gastronomy is sure to surprise you. Nigerian cuisine has many delicious recipes that combine local vegetables, meat, and spices. One of the things that most stand out for everyone who visits this African country is its food. Nigerian cuisine is a rich blend of traditionally African carbohydrates such as yams and cassava and vegetable soups made from native leafy greens. A soup based on palm fruit called Banga is typical of the country. Praised by Nigerians for the strength it gives, Garri is a cassava grainpowder that can be easily consumed as a meal and is relatively inexpensive. Yam is either fried in oil or mashed to make a potato puree. It is different from green peas andis very popular. The meat is also entirely eaten, and Nigeria Suya, a barbecue, is a well-knowndish as a roasting meat method. Bushmeat, meat from wild animals such as deer and giraffes,are also popular. Fermented palm products are used to make a traditional liquor, palm wine, asit is fermented from cassava.",
        recipes: [
            'butter_chicken',
            'dal_makhani',
            'Shahi Paneer',
        ]
    },
]

export const project_team = [
    {
        "name": "Mann Koner",
        "role": "Content Developer",
        "country": "India",
        "program": "HBCOM",
        "favourite_food": "Indian Food",
        "img": "../images/project_team/mann_koner.jpg"
    },
    {
        "name": "Devarsh Patel",
        "role": "Web Developer & Project Lead",
        "country": "India",
        "program": "Masters in Computer Science",
        "favourite_food": "Stir Fry",
        "links": [
            {
              url: "https://devarsh19.github.io/",
              icon: WebsiteIcon,
              title: "Contact Developer"
            },
            {
              url: "https://www.linkedin.com/in/devarsh19/",
              icon: LinkedinIcon,
              title: "LinkedIn"
              
            },
            {
              url: "https://github.com/devarsh19",
              icon: GithubIcon,
              title: "Github"
            },            
          ],
        "img": "../images/project_team/devarsh_patel.png"
    },
    {
        "name": "Gabriela Coba",
        "role": "Content Developer",
        "country": "Ecuador",
        "program": "HBCOM",
        "favourite_food": "Sushi and Pasta",
        "img": "../images/project_team/gabriela_coba.jpg"
    },
    {
        "name": "Qiurui Yan",
        "role": "Graphic Designer",
        "country": "China",
        "program": "MBA",
        "favourite_food": "Sushi",
        "img": "../images/project_team/qiurui_yan.png"
    },
    {
        "name": "Ligia Simba-Bolanos",
        "role": "Video Editor & Post-Production",
        "country": "Ecuador",
        "program": "PhD in Forest Sciences",
        "favourite_food": "Encebollado (a fish stew from Ecuador)",
        "img": "../images/project_team/ligia_simba.jpg"
    },
    {
        "name": "Vinicio Guaman Hernandez",
        "role": "Video Editor & Post-Production",
        "country": "Ecuador",
        "program": "PhD in Forest Sciences",
        "favourite_food": "Plantain Dumplings with Cheese/Pork",
        "img": "../images/project_team/marco_vincio.jpg"
    },
    {
        "name": "Vishwa Shah",
        "role": "Marketing Associate",
        "country": "India",
        "program": "HBCOM",
        "favourite_food": "Samosa",
        "img": "../images/project_team/vishwa_shah.jpg"
    },
];

export const cook_team = [
    {
        "name": "Wenhan Zhang",
        "role": "Cook",
        "country": "China",
        "program": "Forestry",
        "favourite_food": "Sichuan Boiled Fish",
        "img": "../images/cook_team/wenhan_zhang.jpg"
      },
      {
        "name": "Prajwal Dhatwalia",
        "role": "Cook",
        "country": "India",
        "program": "Masters of Computer Science",
        "favourite_food": "Spinach Bread",
        "img": "../images/cook_team/prajwal_dhatwalia.jpg"
      },
      
      {
        "name": "Baljeet Kaur",
        "role": "Cook",
        "country": "India",
        "program": "Masters of Education",
        "favourite_food": "Dal Makhani",
        "img": "../images/cook_team/baljeet_kaur.jpg"
      },
      {
        "name": "Roshan Sapkota",
        "role": "Cook",
        "country": "Nepal ",
        "program": "Masters in Computer Science",
        "favourite_food": "Dumpling",
        "img": "../images/cook_team/roshan_sapkota.jpg"
      },
      
      
      {
        "name": "Bowen Jiang",
        "role": "Cook",
        "country": "China",
        "program": "Masters in Electrical and Computer",
        "favourite_food": "Potato",
        "img": "../images/cook_team/bowen_jiang.jpg"
      },
      
      {
        "name": "Nandini Parekh",
        "role": "Cook",
        "country": "India",
        "program": "HBSc in Biology with Neuroscience ",
        "favourite_food": "Bottle Gourd Curry",
        "img": "../images/cook_team/nandini_parekh.jpg"
      },
      {
        "name": "Danning Jiang",
        "role": "Cook",
        "country": "China",
        "program": "Computer Science",
        "favourite_food": "Beijing Duck",
        "img": "../images/cook_team/danning_jiang.jpg"
      },
      
      {
        "name": "Paul Benalcazar",
        "role": "Cook",
        "country": "Ecuadorian",
        "program": "Forest Science-Phd Program",
        "favourite_food": "Lapingacho - Ecuadorian traditional plate",
        "img": "../images/cook_team/paul_gonzalo.jpg"
      },
      {
        "name": "Xiaoling Chen",
        "role": "Cook",
        "country": "China",
        "program": "MBA",
        "favourite_food": "Dim Sim",
        "img": "../images/cook_team/angela_chen.jpg"
      },      
      {
        "name": "Manoj Kumar Bhuma",
        "role": "Cook",
        "country": "India",
        "program": "Masters in Computer Science",
        "favourite_food": "Butter Chicken and Indian Chat",
        "img": "../images/cook_team/manoj_kumar.jpg"
      },
      {
        "name": "Emmanuel Ibharalu ",
        "role": "Cook",
        "country": "Nigeria",
        "program": "Mechanical engineering ",
        "favourite_food": "Jollof Rice",
        "img": "../images/cook_team/emmanuel_Ibharalu.jpg"
      }
]