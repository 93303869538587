import React from 'react';

function BottomBar(props) {
    const scrollToTop = () => {        
        window.scrollTo(0, 0)
    }
    const style = { display: "flex", justifyContent: "center", padding: "10px", width: "100%", backgroundColor: "#e2e8f0", cursor: "pointer", fontWeight: "700"
}
    return (
        <div style={style} onClick={scrollToTop}>
           Scroll To Top 
        </div>
    );
}

export default BottomBar;