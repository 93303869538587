import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ReactPlayer from "react-player"


import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import { recipes } from "../data";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionHeading } from "components/misc/Headings";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-1`}
    
  }
  h1 {
    ${tw`text-3xl font-bold mt-8 mb-4`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 mb-4`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-1`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`ml-2 mb-1`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const FlexContent = tw.div`flex justify-start`;
const MoreDetailsText = tw.p`mt-2`;
const CountryIconImage = tw.img`max-w-8 ml-2 mr-2`;
const ButtonContainer = tw.div`flex justify-center mb-4`;
const PrimaryButton = tw.button`font-bold px-8 mr-10 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`
const SecondaryButton = tw.button`font-bold px-8 mr-10 lg:px-10 py-3 rounded bg-red-600 text-gray-100 hocus:bg-red-700 focus:shadow-outline focus:outline-none transition duration-300`
const RecipeCoverImageContainer = tw.div`flex justify-center`
const RecipeCoverImage = tw.img`mb-2 max-w-144`
const RecipeStepImage = tw.img`ml-6 mt-4 mb-4 w-128`

const VideoPlayerSection = tw.div`mt-8 mb-4 flex justify-center`
const InfoSection = tw.div`mt-4 flex justify-between`
const InfoItem = tw.div`mt-2 flex flex-col`

// const VideoPlayer = tw(ReactPlayer)`mt-4 mb-4 flex justify-center`

export default (props) => {
    const location = useLocation();
    let pathArray = location.pathname.split("/")    
    pathArray = pathArray.filter( value => !value.startsWith("#"))
    if (pathArray[pathArray.length - 1] == "") pathArray.pop(); 
    const recipe_name_in_url = pathArray[pathArray.length - 1];
    const recipe_name = pathArray[pathArray.length - 1].split("_").join(" ")
    
    const [recipe, setRecipe] = useState({});
    
    useEffect(() => {
        const current_recipe = recipes.filter(recipe => recipe.title.toLowerCase() == recipe_name)
        if (current_recipe.length === 0) setRecipe(null);
        setRecipe(current_recipe[0])
    });
  
    const { ingredients, steps } = recipe
    const renderIngredients = ingredients && ingredients.map((item,key) => {
      return (
        <>
          {item.special_tip === "title" ? <h4>{item.name}</h4> : <li>{item.name} {item.special_tip}</li>}    
        </>
      )
    })
    const renderSteps = steps && steps.map((step, key) => {
      return (
        <div>
          {step.id === "title" ? <h4>{step.instruction}</h4> : <li>{step.instruction}</li>}
          {step.img && <RecipeStepImage src={`../../${step.img}`} />}          
        </div>
      )
    })
    
    return (
        <AnimationRevealPage>
        <Header />
        {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}      
        <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{recipe.title}</Heading>
          </HeadingRow>
          <Text>
            <p>Recipe By: {recipe.cook}</p>
            <p>Written By: {recipe.author}</p>
            <FlexContent>
                <p>Origin: </p>
                <CountryIconImage src={`../../${recipe.countryIcon}`} />
                <p>{recipe.country}</p>
            </FlexContent>
            <br />
            
            {/* two button 1. Jump to recipe 2. Jump to Video */}
            <ButtonContainer>
                {/* <Link to={`/recipes/${recipe_name_in_url}/#recipe`}> */}
                  <PrimaryButton buttonRounded={false} as="a" href={`/recipes/${recipe_name_in_url}/#recipe`}>
                    Jump to Recipe
                  </PrimaryButton>
                {/* </Link> */}
                  <SecondaryButton buttonRounded={false} as="a" href={`/recipes/${recipe_name_in_url}/#recipe_video`}>
                  Jump to Video
                  </SecondaryButton>
            </ButtonContainer>
            
            {/* RECIPE MAIN IMAGE */}
              <RecipeCoverImageContainer>
                <RecipeCoverImage src={`../../${recipe.imageSrc}`} alt="recipe image" />              
            </RecipeCoverImageContainer>

            {/* RECIPE SECTION */}
            <section id="recipe"> 
              <br />
              <p>
                {recipe.long_description}
              </p>

                {/* Intgredients Section */}
              <section>
                <InfoSection>
                    <h2>Ingredients</h2>
                    <h2>Servings: {recipe.number_of_portions}</h2>
                </InfoSection>
                <ul>
                    {renderIngredients}                        
                </ul>                
              </section>
              
              {/* Steps section */}
              <section>
                  <h2>Procedure</h2>
                  <ol start="1">
                      {renderSteps}                        
                  </ol> 
                  
              </section>
            </section>
                        
            {/* Recipe Video */}
            <div id="recipe_video">
              <h2>Recipe Video</h2>
              <VideoPlayerSection >                                
                <ReactPlayer
                  url={recipe.video_url}
                  controls={true}
                />
              </VideoPlayerSection>
            </div>
            
            {/* Additional info container */}
            <InfoSection>
              <InfoItem>
                <h1 style={{marginBottom: "0px"}}>{recipe.cooking_time}</h1>
                <h3 style={{marginTop:"0px"}}>Total time</h3>                
              </InfoItem>
              <InfoItem>
                <h1 style={{marginBottom: "0px"}}>{recipe.number_of_portions}</h1>
                <h3 style={{marginTop:"0px"}}>Number of Servings</h3>
              </InfoItem>
              <InfoItem>
                <h1 style={{marginBottom: "0px"}}>{recipe.taste}</h1>              
                <h3 style={{marginTop:"0px"}}>Flavour</h3>
              </InfoItem>
              <InfoItem>
                <h1 style={{marginBottom: "0px"}}>{recipe.difficulty_level}</h1>              
                <h3 style={{marginTop:"0px"}}>Difficulty</h3>
              </InfoItem>
            </InfoSection>
          </Text>
        </ContentWithPaddingXl>
      </Container>
        <Footer company_name="Cook Book"/>
        </AnimationRevealPage>
    );
};
