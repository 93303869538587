import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line

import HomePage from "pages/HomePage.js";
import RecipesPage from "pages/Recipes.js";
import RecipeDetailPage from "pages/RecipeDetailPage.js";
import CulturePage from "pages/CulturePage";
import TeamPage from "pages/Team.js";
import AboutUsPage from "pages/AboutUs.js";


export default function App() {
  return (
    <Router>
      <Switch>        
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/recipes" component={RecipesPage} />
        <Route exact path="/recipes/:recipe_name" component={RecipeDetailPage} />
        <Route exact path="/culture" component={CulturePage} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/team" component={TeamPage} />
      </Switch>
    </Router>
  );
}