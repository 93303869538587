import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/SimpleFiveColumn.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

  return (
    <AnimationRevealPage>
      <Header />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
             <HighlightedText>Recipes</HighlightedText>
          </>
        }
      />
      <Footer company_name="Cook Book"/>
    </AnimationRevealPage>
  );
};
