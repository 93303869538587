import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import { SectionHeading } from "components/misc/Headings.js";

import { project_team, cook_team } from "../data.js";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => { 
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Team</SectionHeading>          
      <MainFeature1
        // subheading={<Subheading>About team behind this project</Subheading>}
        heading="We are a group of international students living and studying in Thunder Bay"
        description = "The CAHEP/United Way Cookbook Project aims to share a part of the cultural variety of Lakehead University through something very representative and important to different nations: food!"
        buttonRounded={false}
        secondaryButtonText="Meet the Chefs"
        secondaryButtonUrl="/team/#chef_team"
        imageSrc="../../images/team_cover.jpg"
      />
      
      <TeamCardGrid 
        id="executive_team"
        description='"The strength of the team is each individual member. The strength of each member is the team.  -Phil Jackson"'
        heading="Project Team"
        cards={project_team}
      />
      
      <TeamCardGrid 
        id="chef_team"
        description='"Unity is strength. . . when there is teamwork and collaboration, wonderful things can be achieved." -Mattie Stepanek'        
        heading="Chef Team"
        cards={cook_team}
      />    

      <Footer company_name="Cook Book"/>
    </AnimationRevealPage>
  );
};
