import React, { useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import homeImage from "images/home-page.jpg"
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import cookbookLogoImageSrc from "images/cookbook-logo.png"
import pdfIconImageSrc from "images/pdf-icon.png";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Delicious & Healthy <HighlightedText>food recipes</HighlightedText> by international students.</>}
        description="CAHEP/United Way Cookbook Project is a project whose main objective is to share a part of the cultural
        variety of Lakehead University through unique food dishes. Our team has chosen recipes with ingredients that are easy to find
        anywhere in the world. So, take the risk of trying something new and learning a little about the diversity
        that exists in our university."
        imageSrc={homeImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Explore Recipes"
        primaryButtonUrl="/recipes"
        secondaryButtonText="Meet The Team"
        secondaryButtonUrl="/team"
      />

      <Features
        heading={
          <>
            Unique and World famous <HighlightedText>Food dishes</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "15+ Recipes",
            description: "Unique recipes selected from different countries",
            url: "/recipes"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "15+ Chefs",
            description: "Specially made by Chefs with their personal touch",
            url: "/team#chef"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "5 Different Countries",
            description: "Recipes are from India, Nepal, China, Ecuador and Nigeria",
            url: "/culture"
          }
        ]}
        linkText="Know More"
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
    
      <DownloadApp
        text={<>People around you are making these food dishes at their home using our easy to follow <HighlightedTextInverse> Cook Book </HighlightedTextInverse> Click below to view and download PDF version of the CookBook.</>}
        link1Text="Download PDF"
        link1IconSrc={pdfIconImageSrc}
        link1Url="//uwaytbay.ca/CookBook-2021.pdf"
        imageSrc={cookbookLogoImageSrc}
      />
      <Footer company_name="Cook Book"/>
    </AnimationRevealPage>
  );
}
