import React from "react";
import { Link as LinkAnchor } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import BottomBar from "./BottomBar"
import LogoImage from "../../images/logo.png";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw(LinkAnchor)`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const ContactDeveloperDiv = tw.div`flex items-center justify-center font-light bg-gray-400 p-2`;

const date = new Date();
const current_year = date.getFullYear();

export default ({company_name}) => {
  return (
    <Container>
      <BottomBar />
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>{company_name}</LogoText>
          </LogoContainer>
          <CompanyDescription>
            {company_name} is born in collaboration of CAHEP and United Way of Thunder Bay. Whose main objective is to share a part of the cultural
            through their unique food dishes made by internationl students.
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="/recipes">Recipes</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/culture">Culture</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="//uwaytbay.ca/CookBook-2021.pdf" download target="_blank">Download CookBook PDF</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/about-us">About Us</Link>
            </LinkListItem>   
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Featured Recipes</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="/recipes/shanghai_mixed_rice">Shanghai Mixed Rice (China)</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/recipes/llapingachos">Llapingachos (Ecuador)</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/recipes/hyderabadi_chicken_dum_biryani">Hyderabadi Chicken Dum Biryani (India)</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/recipes/chicken_and_egg_bara">Chicken and Egg Bara (Nepal)</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Partners</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="//cahep.ca/" target="_blank">CAHEP</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="//uwaytbay.ca/" target="_blank">UWAY TBAY</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <ContactDeveloperDiv>&nbsp; &#169; {current_year} CookBook - United Way of Thunder Bay. Developed by &nbsp; <a href="https://devarsh19.github.io/" target="_blank"><u>Devarsh Patel</u></a> </ContactDeveloperDiv>
    </Container>
  );
};
