import React from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import {HiAcademicCap, HiLocationMarker } from "react-icons/hi";
import { IoEarthSharp, IoFastFoodSharp} from "react-icons/io5";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`

// const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Card = tw(motion.a)`block mt-12 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled(motion.div)(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-64 h-64 bg-contain bg-center rounded`
]);
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .row {
    ${tw`flex flex-row mr-1`}
  }
  .icon {
    ${tw`m-1 font-bold`}
  }
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const SubContent = styled.div`
  ${tw`flex flex-row justify-between  mt-2`}
  
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-1 flex`}
  .link {
    ${tw`mr-8 last:mr-0 hocus:text-primary-300 text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "Meet These Fine Folks.",
  subheading = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  id = "",
  cards = [    
  ]
}) => {
  // This setting is for animating the post background image on hover
  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };

  return (
    <Container id={id}>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index} initial="rest" whileHover="hover" animate="rest">
              <CardImage
                transition={{ duration: 0.3 }}
                variants={postBackgroundSizeAnimation}              
                imageSrc={card.img}
              />
              <CardContent>
                <span className="name">{card.name}</span>            
                <span className="position">{card.role}</span>
                <p title="Country" className="row"><IoEarthSharp className="icon"/><span>{card.country}</span></p>               
                <p title="Program" className="row"><HiAcademicCap className="icon"/><span>{card.program}</span></p>         
                <p title="Favourite Food" className="row"><IoFastFoodSharp className="icon"/><span>{card.favourite_food}</span></p>
                {card.links &&
                  <CardLinks>
                    {card.links.map((link, linkIndex) => (
                      <a key={linkIndex} className="link" href={link.url} title={link.title} target="_blank">
                        <link.icon className="icon" />
                      </a>
                    ))}
                  </CardLinks>
                }                                
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
