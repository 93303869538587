import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { SectionHeading } from "components/misc/Headings.js";

import CahepImgSrc from "images/cahep.png"
import UnitedWayImgSrc from "images/unitedway.jpg"
import LogoImgSrc from "images/logo.png"


const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>About Us</SectionHeading>
      <MainFeature1
        subheading={<Subheading>About CookBook</Subheading>}
        heading="We are a representing culturals via their unique food dishes"
        description = "The CAHEP/United Way Cookbook Project aims to share a part of the cultural variety of Lakehead University through something very representative and important to different nations: food!"
        primaryButtonText = "Explore Unique Recipes"
        primaryButtonUrl = "/recipes"
        buttonRounded={false}
        imageSrc={LogoImgSrc}
      />
      {/* <h1>Our Partners</h1>
      <hr /> */}
      <SectionHeading>Our Partners</SectionHeading>
      <MainFeature1
        subheading={<Subheading>CAHEP</Subheading>}
        heading="Community Arts & Heritage Education Project (CAHEP)"
        description="We aim to support and empower youth and members of the community through arts and heritage education."
        buttonRounded={false}
        primaryButtonText="Visit CAHEP"
        primaryButtonUrl="https://cahep.ca/"
        imageSrc={CahepImgSrc}
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>UWay TBay</Subheading>}
        heading="United Way of Thunder Bay"
        description="Our misison is to Strengthening local networks to fight poverty and build an inclusive, connected community. We believe that everyone has an important role to play in improving lives and building a strong community.  Our work ensures that every citizen, business and organization has the opportunity to contribute in a way that is meaningful to them.  We truly appreciate your choice to give, volunteer or get involved in the United Way of Thunder Bay."
        buttonRounded={false}
        primaryButtonText="Visit UWay TBay"
        primaryButtonUrl="https://uwaytbay.ca/"
        imageSrc={UnitedWayImgSrc}
        textOnLeft={true}
      />     
      <Footer company_name="Cook Book"/>
    </AnimationRevealPage>
  );
};
